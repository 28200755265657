<template>
  <div>
    <!-- // 考勤详情 -->
    <el-row type="flex" justify="space-between">
      <div class="top-info">
        <el-button class="fontsize18" icon="el-icon-arrow-left" size="mini" @click="goBack"> 返回 </el-button>
        <div class="fontsize18 mr-10">姓名：{{ params.name }}</div>
        <div class="fontsize18">岗位：{{ params.postName }}</div>
      </div>
      <div class="datePicker">
        <el-date-picker
          v-if="showtime"
          v-model="date"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="changeDate"
        >
        </el-date-picker>
      </div>
    </el-row>
    <div class="mt-10">
      <el-table
        header-cell-class-name="table-header"
        cell-class-name="row-cell"
        :data="tableList"
        class="table mt20 table-container"
      >
        <el-table-column
          label="日期"
          prop="clockInDate"
          width="200"
        ></el-table-column>
        <el-table-column label="所在考勤组" prop="clockInSetTitle" width="300">
          <template slot-scope="scope">
            <div
              class="ellipsis"
              style="
                height: 100%;
                line-height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <span>
                {{ scope.row.clockInSetTitle }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="第1班次" prop="name" align="center">
          <template slot-scope="scope">
            <div class="flex space-between">
              <div
                class="row-block"
                :class="scope.row.clockInRecordList[0].goClockInStatus | color"
              >
                <div v-if="scope.row.clockInRecordList[0].goClockInTime">
                  <span>
                    {{
                      parseTime(
                        scope.row.clockInRecordList[0].goClockInTime,
                        "{h}:{i}:{s}"
                      )
                    }}
                    <span
                      v-if="scope.row.clockInRecordList[0].goClockInStatus != 1"
                      >({{
                        scope.row.clockInRecordList[0].goClockInStatus
                          | clockInStatus
                      }})</span
                    >
                  </span>
                </div>
                <div v-else>
                  {{
                    scope.row.clockInRecordList[0].goClockInStatus
                      | clockInStatus
                  }}
                </div>
              </div>
              <div
                class="row-block"
                :class="scope.row.clockInRecordList[0].offClockInStatus | color"
              >
                <div v-if="scope.row.clockInRecordList[0].offClockInTime">
                  <span>
                    {{
                      parseTime(
                        scope.row.clockInRecordList[0].offClockInTime,
                        "{h}:{i}:{s}"
                      )
                    }}
                    <span
                      v-if="
                        scope.row.clockInRecordList[0].offClockInStatus != 1
                      "
                      >({{
                        scope.row.clockInRecordList[0].offClockInStatus
                          | clockInStatus
                      }})</span
                    >
                  </span>
                </div>
                <div v-else>
                  {{
                    scope.row.clockInRecordList[0].offClockInStatus
                      | clockInStatus
                  }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="第2班次"
          prop="name"
          align="center"
          v-if="showColumns[1] > 0"
        >
          <template slot-scope="scope">
            <div
              class="flex space-between"
              v-if="scope.row.clockInRecordList[1]"
            >
              <div
                class="row-block"
                :class="scope.row.clockInRecordList[1].goClockInStatus | color"
              >
                <div v-if="scope.row.clockInRecordList[1].goClockInTime">
                  <span>
                    {{
                      parseTime(
                        scope.row.clockInRecordList[1].goClockInTime,
                        "{h}:{i}:{s}"
                      )
                    }}
                    <span
                      v-if="scope.row.clockInRecordList[1].goClockInStatus != 1"
                      >({{
                        scope.row.clockInRecordList[1].goClockInStatus
                          | clockInStatus
                      }})</span
                    >
                  </span>
                </div>
                <div v-else>
                  {{
                    scope.row.clockInRecordList[1].goClockInStatus
                      | clockInStatus
                  }}
                </div>
              </div>
              <div
                class="row-block"
                :class="scope.row.clockInRecordList[1].offClockInStatus | color"
              >
                <div v-if="scope.row.clockInRecordList[1].offClockInTime">
                  <span>
                    {{
                      parseTime(
                        scope.row.clockInRecordList[1].offClockInTime,
                        "{h}:{i}:{s}"
                      )
                    }}
                    <span
                      v-if="
                        scope.row.clockInRecordList[1].offClockInStatus != 1
                      "
                      >({{
                        scope.row.clockInRecordList[1].offClockInStatus
                          | clockInStatus
                      }})</span
                    >
                  </span>
                </div>
                <div v-else>
                  {{
                    scope.row.clockInRecordList[1].offClockInStatus
                      | clockInStatus
                  }}
                </div>
              </div>
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column
          label="第3班次"
          prop="name"
          align="center"
          v-if="showColumns[2] > 0"
        >
          <template slot-scope="scope">
            <div
              class="flex space-between"
              v-if="scope.row.clockInRecordList[2]"
            >
              <div
                class="row-block"
                :class="scope.row.clockInRecordList[2].goClockInStatus | color"
              >
                <div v-if="scope.row.clockInRecordList[2].goClockInTime">
                  <span>
                    {{
                      parseTime(
                        scope.row.clockInRecordList[2].goClockInTime,
                        "{h}:{i}:{s}"
                      )
                    }}

                    <span
                      v-if="scope.row.clockInRecordList[2].goClockInStatus != 1"
                      >({{
                        scope.row.clockInRecordList[2].goClockInStatus
                          | clockInStatus
                      }})</span
                    >
                  </span>
                </div>
                <div
                  v-else
                  :class="{
                    status1:
                      scope.row.clockInRecordList[2].goClockInStatus == 3,
                    status2:
                      scope.row.clockInRecordList[2].goClockInStatus != 1 &&
                      scope.row.clockInRecordList[2].goClockInStatus != 2,
                  }"
                >
                  {{
                    scope.row.clockInRecordList[2].goClockInStatus
                      | clockInStatus
                  }}
                </div>
              </div>
              <div
                class="row-block"
                :class="scope.row.clockInRecordList[2].offClockInStatus | color"
              >
                <div v-if="scope.row.clockInRecordList[2].offClockInTime">
                  <span>
                    {{
                      parseTime(
                        scope.row.clockInRecordList[2].offClockInTime,
                        "{h}:{i}:{s}"
                      )
                    }}

                    <span
                      v-if="
                        scope.row.clockInRecordList[2].offClockInStatus != 1
                      "
                      >({{
                        scope.row.clockInRecordList[2].offClockInStatus
                          | clockInStatus
                      }})</span
                    >
                  </span>
                </div>
                <div v-else>
                  {{
                    scope.row.clockInRecordList[2].offClockInStatus
                      | clockInStatus
                  }}
                </div>
              </div>
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column
          label="第4班次"
          prop="name"
          align="center"
          v-if="showColumns[3] > 0"
        >
          <template slot-scope="scope">
            <div
              class="flex space-between"
              v-if="scope.row.clockInRecordList[3]"
            >
              <div
                class="row-block"
                :class="scope.row.clockInRecordList[3].goClockInStatus | color"
              >
                <div v-if="scope.row.clockInRecordList[3].goClockInTime">
                  <span>
                    {{
                      parseTime(
                        scope.row.clockInRecordList[3].goClockInTime,
                        "{h}:{i}:{s}"
                      )
                    }}

                    <span
                      v-if="scope.row.clockInRecordList[3].goClockInStatus != 1"
                      >({{
                        scope.row.clockInRecordList[3].goClockInStatus
                          | clockInStatus
                      }})</span
                    >
                  </span>
                </div>
                <div v-else>
                  {{
                    scope.row.clockInRecordList[3].goClockInStatus
                      | clockInStatus
                  }}
                </div>
              </div>
              <div
                class="row-block"
                :class="scope.row.clockInRecordList[3].offClockInStatus | color"
              >
                <div v-if="scope.row.clockInRecordList[3].offClockInTime">
                  <span>
                    {{
                      parseTime(
                        scope.row.clockInRecordList[3].offClockInTime,
                        "{h}:{i}:{s}"
                      )
                    }}
                    <span
                      v-if="
                        scope.row.clockInRecordList[3].offClockInStatus != 1
                      "
                      >({{
                        scope.row.clockInRecordList[3].offClockInStatus
                          | clockInStatus
                      }})</span
                    >
                  </span>
                </div>
                <div v-else>
                  {{
                    scope.row.clockInRecordList[3].offClockInStatus
                      | clockInStatus
                  }}
                </div>
              </div>
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block mt10 pagetion">
        <el-pagination
          layout="total, prev, pager, next"
          :total="pageData.total"
          :page-size="pageData.pageSize"
          :current-page.sync="pageData.pageNum"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// import TopHeader from "./components/top-header.vue";
import moment from "moment"
import { getAttendance } from "./api.js";
// import Template from "../../components/calendarUi/template.vue";
export default {
  components: {
    // TopHeader,
    // Template,
  },
  data() {
    return {
      pageData: {
        pageNum: 1,
        total: 0,
        pageSize: 10,
      },
      tableList: [],
      date: [],
      params: {},
      showColumns: [0, 0, 0, 0],
      showtime:false
    };
  },
  created(){
    this.params = this.$route.query;
    var dates = sessionStorage.getItem('dates')
    dates = moment(dates).format("yyyy-MM-DD")
    console.log(dates)
    // this.$set(this.date,0,dates)
    // this.$set(this.date,1,dates)
    this.date = [dates, dates];
    this.showtime = true

    console.log( this.date)
  },
  mounted() {

    this.calcHeight();
  },

  methods: {
    getData() {
      getAttendance({
        projectUserInfoHistoryId: this.params.projectUserInfoHistoryId,
        ...this.pageData,
        clockInStartDate: this.date[0],
        clockInEndDate: this.date[1],
      }).then((res) => {
        this.tableList = res.data.rows;

        this.pageData.total = res.data.total;
        let showColumns = [0, 0, 0, 0];
        this.tableList.forEach((i) => {
          if (i.clockInRecordList.length == 1) {
            showColumns[0]++;
          }
          if (i.clockInRecordList.length == 2) {
            showColumns[0]++;
            showColumns[1]++;
          }
          if (i.clockInRecordList.length == 3) {
            showColumns[0]++;
            showColumns[1]++;
            showColumns[2]++;
          }
          if (i.clockInRecordList.length == 4) {
            showColumns[0]++;
            showColumns[1]++;
            showColumns[2]++;
            showColumns[3]++;
          }
          this.showColumns = showColumns;
        });
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    changeDate() {
      this.getData();
    },
    calcHeight() {
      if (this.pageData.pageNum > 1) {
        return;
      }
      const myElement = document.getElementsByClassName("top-info")[0];
      const rect = myElement.getBoundingClientRect();
      const distanceToBottom = window.innerHeight - rect.bottom - 200;
      this.pageData.pageSize = Math.floor(distanceToBottom / 60);
      this.getData();
    },
    handleResize() {
      this.calcHeight();
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  filters: {
    clockInStatus(val) {
      if (val == 2) {
        return "缺卡";
      } else if (val == 3) {
        return "迟到";
      } else if (val == 4) {
        return "早退";
      } else if (val == 5) {
        return "请假";
      }else if (val == 6) {
        return "休息";
      }else if (val == 7) {
        return "休息/请假";
      }
    },
    color(val) {
      if (val == 3 || val == 4) {
        return "status1";
      } else if (val != 1 && val != 3) {
        return "status2";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.top-info {
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
}
.datePicker ::v-deep .el-input__inner,
.datePicker ::v-deep .el-input__inner .el-range-separator {
  background: rgba(0, 0, 0, 0);
  color: #fff;
}
.datePicker{
  display: flex;
  justify-content: center;
}
.datePicker ::v-deep .el-range-input {
  background: rgba(0, 0, 0, 0);
  color: #fff;
}
::v-deep .el-table {
  background: rgba(0, 0, 0, 0);
}
::v-deep .el-table tr {
  background: rgba(0, 0, 0, 0);
}
.row-block {
  &:nth-of-type(1) {
    border-right: 2px solid rgb(91, 111, 143);
  }
  height: 100%;
  flex: 1;
  text-align: center;
  color: #0ac200;
}

.status1 {
  color: #ff0000;
}
.status2 {
  color: #fb8400;
}

::v-deep .row-cell.el-table__cell {
  padding: 0;
}
::v-deep .row-cell.el-table__cell .cell {
  height: 100%;
  line-height: 60px;
}
::v-deep .row-cell.el-table__cell .cell .flex.space-between {
  height: 100%;
  line-height: 60px;
  display: flex;
  align-items: center;
}
.ellipsis {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* 限制显示两行 */
  -webkit-box-orient: vertical;
}
.mt-10{
  margin-top: 0.1rem;
}

</style>
